import React from "react";
import {Space} from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import "assets/styles/loader.less";

interface Props {
    tip: string,
    spinning: boolean
}

const Spinner: React.FC<Props> = ({spinning, tip}) => {
    return (
        <div className={"spinner-container"}>
            <Space direction={"vertical"}>
                <img src={"/logo.png"} alt={"ARIFAH LOGO"} />
                <div style={{textAlign: "center"}}>
                    <LoadingOutlined style={{ fontSize: 25, color : "#ffffff" }} spin={true} />
                </div>
            </Space>
        </div>
    )
};

export default Spinner;