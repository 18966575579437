import React from 'react';

interface Pages {
    path      : string,
    isExact   : boolean,
    component : React.LazyExoticComponent<React.FC>,
    isPublic  : boolean
}

let pages : Pages[] = [
   {
        path      : "/",
        isExact   : true,
        component : React.lazy(() => import('../pages/home')),
        isPublic  : true,
    },
    {
        path      : "/jenjang-pendidikan",
        isExact   : true,
        component : React.lazy(() => import('../pages/education')),
        isPublic  : true,
    },
    {
        path      : "/jenjang-pendidikan/biaya",
        isExact   : true,
        component : React.lazy(() => import('../pages/cost')),
        isPublic  : true,
    },
    {
        path      : "/jenjang-pendidikan/pendaftaran",
        isExact   : true,
        component : React.lazy(() => import('../pages/register')),
        isPublic  : true,
    },
    {
        path      : "/jenjang-pendidikan/pendaftaran/sukses",
        isExact   : true,
        component : React.lazy(() => import('../pages/success')),
        isPublic  : true,
    },
    {
        path      : "/status",
        isExact   : true,
        component : React.lazy(() => import('../pages/status')),
        isPublic  : true,
    }
]

export default pages