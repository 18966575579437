import Layout from "./layout";
import Loader from "./loader";
import Header from "./header";

const component = {
    Layout,
    Loader,
    Header
};

export default component