import React from "react";
import {Layout} from 'antd';

interface Props {
    hideMenu?   : boolean,
    hideVactor? : boolean,
    useVector2? : boolean,
}

const BaseLayout : React.FC<Props> = (props) => {

    React.useEffect(() => {

        window.scrollTo(0, 0);

    }, [])

    return (
        <Layout className={props.hideVactor ? "layout hide-vector" : "layout"}>
            {
                (!props.hideVactor && !props.useVector2) && <div className={"vector"}/>
            }

            {
                props.useVector2 && <div className={"vector-2"}/>
            }
            <Layout.Content>
                {props.children}
            </Layout.Content>

        </Layout>
    )
}

export default BaseLayout;