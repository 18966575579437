import React, {createContext, useEffect, useState} from "react";
import firebase from "firebase/app";
import "firebase/auth";

interface User extends firebase.User {
    address : string,
    role : "admin" | "user"
}

type ContextProps = [{
        authenticated : boolean,
        isLoading     : boolean,
        user          : User | null
    }]

export const SessionContext = createContext<ContextProps>([{ authenticated : true, isLoading : true, user : null}]);

export const SessionProvider : React.FC = (props) => {

    const [authenticated, setAuthenticated] = useState<boolean>(true);
    const [user, setUser]                   = useState<User|null>(null);
    const [isLoading, setLoading]           = useState<boolean>(true);

    useEffect(() => {

        setLoading(true);

        firebase.auth()
            .onAuthStateChanged( async (user ) => {
            if (user !== null) {

                const refreshToken = await user.getIdTokenResult();

                setUser({...user, ...refreshToken.claims} as User);
                setAuthenticated(true);
                setLoading(false);

            } else {

                setAuthenticated(false);
                setUser(null);
                setLoading(false);
            }
        })

    }, []);

    return (
        <SessionContext.Provider
            value={[{
                authenticated,
                isLoading,
                user
            }]}
        >
            {props.children}
        </SessionContext.Provider>
    )
}
