import React from "react";
import { Col, Layout,  Row, Space} from "antd";

const Header: React.FC = () => {

    return (
        <Layout.Header id={"navbar"} className="header">
           <div className={"avatar"}>
               <Row align="middle">
                   <Col span={3}>
                       <Space>
                           <img width={`50px`} className={"logo"} src={"/logo.png"} alt={"ARIFAH LOGO"} />
                           <strong>Arifah</strong>
                       </Space>
                   </Col>
               </Row>
           </div>
        </Layout.Header>
    )
}

export default React.memo(Header);